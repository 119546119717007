//
//
//
//

import { mapActions } from 'vuex';

export default {

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadSettingsTechnicalWork(),
      this.loadSettingsVerification(),
      this.loadSettingsUploadOptions(),
      this.loadSettingsLimits(),
      this.loadTradingOptions(),
      this.loadSettingsIndividual(),
      this.loadSettingsSystemNotifications(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('settingsNotifications', {
      loadSettingsNotifications: 'loadData',
    }),
    ...mapActions('settingsEmail', {
      loadSettingsEmail: 'loadData',
    }),
    ...mapActions('settingsLimits', {
      loadSettingsLimits: 'loadData',
      loadTradingOptions: 'loadTradingOptions',
    }),
    ...mapActions('settingsTechnicalWork', {
      loadSettingsTechnicalWork: 'loadData',
    }),
    ...mapActions('settingsVerification', {
      loadSettingsVerification: 'loadData',
    }),
    ...mapActions('settingsUploadOptions', {
      loadSettingsUploadOptions: 'loadData',
    }),
    ...mapActions('settingsIndividual', {
      loadSettingsIndividual: 'loadData',
    }),
    ...mapActions('settingsSystemNotifications', {
      loadSettingsSystemNotifications: 'loadData',
    }),
  },
};
